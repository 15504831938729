import React from 'react';
import Spinner from "components/Spinner"
import loadable from '@loadable/component';

const LoadableTabTesting = loadable(() => import('components/TabTesting'), {
  fallback: (
    <Spinner />
  ),
});
const TabTestingPage = () => {
  return <LoadableTabTesting />;
};
export default TabTestingPage;
